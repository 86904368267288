
/* Header */
.header {
   display: flex;
   align-items: stretch;
   width: 100%;
   justify-content: space-between;
   background-color: #1a1161;
   color: #ffffff;
   position: sticky;
   top: 0px;
   z-index: 300;
   border-bottom: 3px double #ffffff;
   border-top: 3px double #ffffff;
   left: 0px;
   right: 0px;
   height: 65px;
   font-size: calc(12px + 1vmin);
}

.header_title {
   font-weight: bold;
   font-size: calc(14px + 1vmin);
   color: #ffffff;
   padding-top: 27px;
   flex: auto;
   text-decoration: none;
}

.header_navigation {
   display: flex;
   gap: 20px;
   padding: 10px;
}

.header_nav_icon {
   width: 25px;
   margin-bottom: -5px; 
 }

.header_navigation .headerItem {
   text-decoration: none;
   color: #ffffff;
   font-weight: 400;
   font-size: 16px;
   padding: 15px;
   align-self: center;
}

.header_navigation a:hover {
   cursor: pointer;
   background-color: #3A26D9;
   color: #ffffff;
   border-radius: 6px;
}

.header_nav_icon {
   color: #1a1161;
}
.header_navigation a:hover > .header_nav_icon {
   color: white;
}

.barIcon {
   display:none;
}

.signupButton {
   font-size: calc(10px + 1vmin);
   padding: 10px 20px 5px 20px;
   margin: 10px 20px 0px 10px;
   height: 30px;
   cursor: pointer;
}

.dropdownItem {
   color: #1a1161;
   display: block;
   padding: 4px 10px;
   padding-left: 15px;
   font-size: 16px;
   line-height: 24px;
   text-decoration: none;
   margin-left: -10px;
}

.dropdownItem.admin {
   padding-left: 30px;
   text-decoration: underline;
}

@media (max-width: 768px) {
   .nonMobileOnly {
      display: none;
   }

   .header_navigation {
      display: none; /* Hide the navigation links by default */
      flex-direction: column;
      position: absolute;
      right: 0;
      z-index: 100;
      width: 30%;
      min-width: 200px;
      float:right;
      color: #0E0F11;
      background-color: #ffffff;
    }

    .header_navigation .headerItem {
      color: #0E0F11;
   }
  
    .header_navigation.open {
      display: flex; /* Show the navigation links when the menu is open */
      height: calc(100vh);
    }
  
    .header_nav_icon {
      width: 25px;
      margin-bottom: -5px; 
    }

   .barIcon {
      color: white;
      font-size: 24px;
      display: inline-block;
      margin-top:20px;
      margin-right:30px;
   }

   .header_navigation .headerItem {
      padding: 0px;
   }

   .userIcon {
      color: white;
      font-size: 16px;
      margin-top: -5px;
      padding-top: 5px;
      padding-left: 5px;
      padding-right: 5px;
      border: 3px solid white;
      border-radius: 50%;
      float: left;
      margin-top:10px;
   }


   .dropdownMenuItem {
      text-decoration: none;
      color: #0E0F11;
      font-weight: 400;
      font-size: 16px;
      padding: 0px 5px;
      padding-top: 12px;
      display: inline-block
   }
 }

 @media (min-width: 768px) {
   .mobileOnly {
      display: none;
   }

   .dropdownMenuItem {
      width: 156px;
      color: #1a1161;
      border: 1px solid #1a1161;
      margin-left: -121px;
      display: block;
      padding: 4px 10px;
      padding-left: 10px;
      position: fixed;
      font-size: 16px;
      background-color: #ffffff;
      border-radius: 6px;
      line-height: 24px;
   }

   .userIcon {
      color: white;
      font-size: 24px;
      margin-top: -5px;
      padding-top: 5px;
      padding-left: 5px;
      padding-right: 5px;
      border: 3px solid white;
      border-radius: 50%;
   }
   
   .userIconA {
      padding-left: '30px';
      padding-right: '30px';
      display: inline-block;
   }
}