.App {
  text-align: center;
}

.tagPro {
  position: relative;
}

.proImg {
  width: 20px;
  height: 20px;
  margin-left: -15px;
}

.logoSpaceHolder {
  padding-right: 15px;
}



.popover {
  display: none; /* Initially hide the popover */
  position: absolute;
  background: #fff;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  z-index: 10;
  /*top: calc(100% + 10px); /* Adjust this value to control the distance from the element */
  transform: translateX(-50%);
  white-space: normal; 
  max-width: 300px; 
  max-height: 200px; 
  overflow-y: auto; 
  margin-left: 75px;
  margin-top: 10px;
}

.popoverParent {
  position: relative;
}

/* Show the popover when hovering over the element */
.popoverParent:hover .popover{
  display: block !important;
}

/* Show the popover when hovering over the element */
.popoverHover:hover .popover{
  display: block !important;
}
