/*
Color       Hex         Use
----------  ----------  -----------------------------------------------
Navy:       #1a1161 /1E1147    Background on Header/Footer
Lt Navy:    #3A26D9 /4C5980    Contrast on Navy
Green:      #12AC7B     Success, Update, Attention
Lt Grey:    #F2F3F3 /F7F7F7    Background, Highlight Functions, Borders
Md Grey     #cccccc     Selected (Contrast on Lt Grey or White)
Dark Grey:  #0E0F11     Text and Borders (Replaces Flat Black)
Purple:     #7A64EB     Key Functions, Icons, Preferred Actions
Lt Purple   #F6F5FF     Faded main purple for background hightlights
Orange:     #E35640     Caution, Warning, Errors
*/

body {
   color: #0E0F11;
   line-height: 23px;
   font-size: 14px;
   font-family: 'Lato';
   display: block;
   margin: 0;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

#root  {
   display: block;
}

.logo {
   height: 40px;
   padding: 14px 20px 0px 40px;
}

@media (max-width: 500px) {
   .logo {
      height: 30px;
      padding: 18px 10px 0px 20px;
   }
}

@media (max-width: 400px) {
   .logo {
      height: 25px;
      padding: 20px 10px 0px 10px;
   }
}

.mainBox {
   padding: 20px;
}

.defaultFont {
   color: #0E0F11;
   font-weight: 300;
   font-size: 14px;
   font-family: 'Lato';
}

.errorText {
   color: #E35640;
   font-size: 16px;
   margin-top: 5px;
}

.linkText {
   color: #7A64EB;
   cursor: pointer;
   font-weight: 600;
   padding-left: 10px;
   padding-right: 10px;
   background: none;
   border: none;
}
.link {
   cursor: pointer;
   text-decoration: underline;
}
.greenText {
   color: #12AC7B;
}
.purpleText {
   color: #7A64EB;
}
.orangeText {
   color: #E35640;
}
.dkGrayText {
   color: #999999;
}
.navyText {
   color: #1E1147;
}
.lightNavyText {
   color: #4C5980;
}
.lightPurpleBackground {
   background-color: rgba(122, 100, 235, 0.06);
}
.lightGrayBackground {
   background-color: #F7F7F7;
}

.headline {
   font-size: 16px;
   font-weight: 600;
}

.step {
   transition: all 0.3s ease;
 }

.floatRight {
   float: right;
}

.sizeUp {
   font-size: 18px;
}

.OneHalfWidth {
   width: 50%;
   float: left;
}

.Padded {
   padding: 5px;
}
.DeepPadded {
   padding: 25px;
}

.MinExSmall {
   min-width: 100px;
}
.MinSmall {
   min-width: 200px;
}

.label {
   font-weight: 600;
   align-self: center;
   padding-right: 10px;
}
.leftLabel {
   font-weight: 400;
   font-size: 16px;
   text-align:left;
}
.topPadded {
   padding-top: 10px;
}

.fullWidth {
   width:100%;
   margin: 0px;
}

.subHeader {
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
}
.large {
   font-size: 22px;
   line-height: 30px;
}

.bold {
   font-weight: 600;
}

/* Date Picker */
.rs-picker-popup.rs-picker-popup-date {
   z-index: 9999;   
   margin: 0px;
}

.rs-picker-error > .rs-input-group {
   border-color: #ccc !important;
}

.rs-picker-date > .rs-input-group.rs-input-group-inside .rs-input {
   margin: 0px;
   border-color: #ccc
}

/* Icons */
.SmallIcon {
   width: 16px;
   vertical-align: top;
   cursor: pointer;
}

.baseIcon {
   font-size: 20px;
   cursor: pointer;
}

.baseIcon.light {
   background-color: #ffffff; 
   border: 1px solid #D9DBDD;
   border-radius: 50%;
}

.circleIcon {
   background-color: #f5f5f5;
   margin-top: -5px;
   padding: 5px 8px;
   border-radius: 50%;
}
.userCircle {
   float: left;
   border: 1px solid black;
   width: 32px;
   height: 30px;
   margin-top: 0px;
   padding: 0px;
   text-align: center;
   line-height: 2.1;
   font-size: 14px;
}
.userCircle.small {
   width: 22px;
   height: 20px;
   line-height: 1.7;
   font-size: 12px;
}

.closeTrashIcon {
   background-color: #fff;
    padding: 5px 7px 5px 7px;
    border-radius: 50%;
    border: 1px solid;
}

@media (max-width: 768px) {
   .fileUploadIcon {
      height: 120px;
   }
}

@media (min-width: 768px) {
   .fileUploadIcon {
      height: 225px;
   }
}

.pageIcon {
   font-size: 16px;
   padding: 15px 10px;
   float: right;
   margin-bottom: -5px;
   cursor: pointer;
}

.pageIconSelected {
   background-color: #F2F3F3;
}

.loadingIcon {
   width: 20px;
}

/* Footer */
.footer {
   display: flex;
   align-items: stretch;
   justify-content: space-between;
   background-color: #1a1161;
   color: #ffffff;
   padding: 10px;
   bottom: 0px;
   z-index: 300;
   border-bottom: 3px double #ffffff;
   border-top: 3px double #ffffff;
}

/* Animations */
.table-containerA {
   position: absolute;
   top: 100;
   width: 100%;
   height: 100%;
   overflow: hidden;
 }


 .slide-out-left {
   transform: translateX(-100%);
   transition: transform 0.5s ease-in-out;
 }
 
 .slide-in {
   transform: translateX(0);
   transition: transform 0.5s ease-in-out;
 }
 
 .slide-out-right {
   transform: translateX(100%);
   transition: transform 0.5s ease-in-out;
 }
 
 .offscreen-left {
   transform: translateX(-100%);
 }
 
 .offscreen-right {
   transform: translateX(100%);
 }

 .hidden {
   display: none;
 }

/* Shot Images */

.shotImage {
   max-height: calc(30vh);
   max-width: 80%
}

@media (max-width: 1150px) {
   .shotImageWide {
      flex-grow: 1;
   }
}
@media (min-width: 1150px) {
   .shotImageWide {
      width: 200px;
   }
}

.ImageSizeDropdown {
   width: 110px;
   margin-top: -8px;
   float: left;
}
/* Landing Page */
.containedPage {
   height: calc(100vh - 120px);
   display: block;
}

/* Centered Block */
.centeredBlock {
   color: #1a1161;
   min-height: 100vh;
   width: 80%;
   display: block;
   margin-left: 10%;
   font-size: calc(10px + 2vmin);
}

.centered {
   text-align: center;
   display: block;
   width: 100%;
}

/* File Uploader */
.addFile {
   padding: 30px;
   border: 4px double #cccccc;
}


@media (max-width: 791px) {
   .exportModalSpacing {
      display:none;
   }
}

@media (min-width: 791px) {
   .exportModalSpacing {
      display:unset;
   }
}

/* MUI */
.word-wrap-header .MuiDataGrid-columnHeaderTitle {
   white-space: normal !important;
   line-height: 1.2 !important;
   word-wrap: break-word !important;
   overflow-wrap: break-word !important;
   text-align: center;
 }
 

/* Shot List */
.shotItemList {
   word-wrap: break-word;
   white-space: pre-wrap;
   margin: 20px;
   margin-top: 40px;
   padding: 20px;
}

.highlighted {
   font-weight: bold;
}

.shotType {
   flex: 1;
   font-family: 'Lato';
   margin-top: 5px;
   border: #ffffff 1px solid;
   min-height: 38px;
   margin-bottom: 5px;
}

.scriptContainer {
   border: 1px solid #ffffff;
   padding: 10px;
   margin-top: 10px;
}

.scriptNav {
   display: flex;
   justify-content: center;
   margin-bottom: 10px;
}

.scriptNav button {
   margin: 0 5px;
}

.loadingMessage {
   margin-top: 30px;
   text-align: center;
   font-size: 20px;
}

.hiddenScroll {
   overflow: hidden;
   padding-right: 30px;
}
.hiddenScroll:hover {
   overflow-y: scroll;
   padding-right:18px;
}

.limitHeightScene {
   height: calc(100vh - 200px);
}
.limitHeight {
   height: calc(100vh - 257px);
}

.limitHeightSmall {
   height: calc(50vh - 10px);
}

.shotCount {
   font-size: 18px;
   font-weight: 600;
   min-width: 65px;
   max-width: 100px;
   float: inline-start;
   padding-top: 10px;
   padding-bottom: 20px;
}
.shotListOptions {
   min-width: 145px;
   border: 1px solid #F7F7F7;
   border-radius: 8px;
   text-align: right;
   margin: 0px 5px 5px 5px;
   float: inline-end;
}
/* PDF Viewer */
.annotationLayer {
   display: none;
}

.halfFlex {
   flex: 1;
   padding-left: 10px;
   padding-right: 10px;
}

.pdfContainer {
   /* height: /*screenHeight - 200px*/
   overflow: auto; /* Enable both vertical and horizontal scrolling */
   border: 1px solid #ffffff;
   padding: 10px;
   height: calc(35vh - 10px);
   resize: vertical;
}

@media (max-width: 768px) {
   .pdfContainer {
      max-height: calc(20vh);
   }
}


.pdfContainer1 {
   width: 98%;
}

.pdfWrapper {
   display: flex; /* Use flex to align pages vertically */
   flex-direction: column; /* Set the flex direction to column */
   align-items: center; /* Align pages to the start of the column */
   max-width: calc(70vw);
}


.pdfPage {
   margin-bottom: 10px; /* Add some spacing between pages */
}
.addBottomSpacing {
   margin-bottom: 15px; /* Add some spacing between pages */
}


/* Shot List */
.shotList {
   display: flex;
   flex-direction: column;
}

.shotHeaderBar {
   border-bottom: 1px solid #F2F3F3;
}
.shotHeader {
   line-height: 32px;
   font-size: 24px;
   font-weight: 400;
   margin: 0px;
   padding-right: 0px;
   padding-left: 30px;
}


.progress-bar-text {
   font-size: 16px;
   font-weight:400;
   display:block;
}
.progress-bar-container {
   width: 70%;
   height: 15px;
   background-color: #ffffff;
   border: 1px solid #7A64EB;
   border-radius: 6px;
   overflow: hidden;
   margin: 5 10;
   display:inline-flex;
}
.progress-bar {
   height: 100%;
   background-color: #7A64EB;
   width: 0%; /* Initially set to 0, but will be controlled by inline style */
}

.item {
   align-items: center;
   padding: 0px 10px 0px 20px;
}

.selected {
   border: 1px solid #7A64EB;
}
.selectedShot {
   border: 1px solid #ccc;
   border-left: 4px solid #7A64EB;
}

.shotNumber {
   width: 50px;
   margin-right: 8px;
   font-weight: bold;
}

.highlighted {
   background-color: yellow;
}

.hidden {
   display: none !important;
}

.optionList {
   display: flex; 
   flex-direction: column;
   border: 1px solid #cccccc;
   border-radius: 8px;
   margin-left:10px;
   align-items: center;
   max-width: 60px;
}
.optionListHorizontal {
   flex-direction: row;
   background-color: #fff;
   border: 1px solid #ccc;
   border-radius: 8px;
   align-items: center;
   margin-bottom: 3px;
}
.optionList > .linkText{
   color: #0E0F11;
   font-size:  18;
}

.numerator {
   font-size: 10px; 
   vertical-align: super;
 }
 
.denominator {
   font-size: 10px; /* Adjust the font size for the denominator */
   vertical-align: sub; /* Move it slightly below the baseline */
 }

 @media (max-width: 768px) {
   .noShotlistIconBox {
      padding-top: 0px;
      margin-bottom: -20px;
   }
   .noShotlistIcon {   
      font-size: 40px;
      padding: 20px;
    }
}

@media (min-width: 768px) {
   .noShotlistIconBox {
      padding-top: 50px;
   }
   .noShotlistIcon {   
      font-size: 120px;
      padding: 40px;
    }
}

/* ScriptList.css */
.scriptListContainer {
   margin-top: 20px;
   width:100%;
}

.scriptList {
   list-style: none;
   padding: 0;
   display:flex;
}

.scriptListItem {
   flex-direction: column;
   border: 1px solid #ccc;
   border-radius: 10px;
   padding: 0px 10px 0px 10px;
   margin: 10px;
   flex: 1;
   min-width: 300px;
}

.scriptListItem div {
   margin-bottom: 10px;
}


/* Scrollbar styles */
::-webkit-scrollbar {
   width: 12px;
   height: 12px;
   }
   
   ::-webkit-scrollbar-track {
   background: #f5f5f5;
   border-radius: 10px;
   }
   
   ::-webkit-scrollbar-thumb {
   border-radius: 10px;
   background: #ccc;  
   }
   
   ::-webkit-scrollbar-thumb:hover {
   background: #999;  
   }
