/* Modal */
.modal {
  position: absolute;
  background-color: #fff;
  color: #0E0F11;
  padding: 30px;
  border-radius: 6px;
  overflow: auto;
  outline: none;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  max-height: calc(85vh);
}


@media (max-width: 768px) {
  .modal {
    top: calc(10vh);
    left: calc(10vw);
    width: 90%;
    max-width: calc(80vw);
    max-height: calc(80vh);
  }
}

@media (min-width: 768px) {
  .modal {
    top: 40px;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 80%;
    max-width: 500px;
    max-height: calc(85vh);
  }
  .modal.wide {
    max-width: 800px;
  }
}
.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75) !important;
  display: flex;
  align-items: center; /* Align vertical */
  justify-content: center; /* Align horizontal */
  z-index:1301;
}

.modalHeader {
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 1px solid #cccccc;
  line-height:24px;
}

.modalContent {
  padding-top: 20px;
  font-size: 16px;
  line-height: 21px;
}

/* Modal form */
.modal form {
  display: flex;
  flex-direction: column;
}

.modal form label {
  display: block;
  margin-bottom: 10px;
  color: #1A1161;
  font-size: 18px;
  font-weight: bold;
}

.modal form input {
  display: block;
  width: 100%; /* Make the input take up the full width of the form */
  box-sizing: border-box; /* Ensure padding and border are included in element's total width and height */
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #1A1161;
  border-radius: 5px;
}

/*
.modal form button {
  padding: 10px;
  border: 1px solid #1A1161;
  background-color: #1A1161;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 18px;
  border-radius: 15px 1px;
  transition: background-color 0.3s ease;
}

.modal form button:hover {
  background-color: #a899a8;
}

/* Modal Close button 
.modal button.ReactModal__CloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #E4F1F8;
  color: #1A1161;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
}

.modal button.ReactModal__CloseButton:hover {
  background-color: #a899a8;
}
*/

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}


.modalHeader {
padding: 0px 0px 20px 0px;
font-size: 18px;
font-weight: 600;
}


.modalMobile {
  width: 100%;
  z-index: 1400;
  left: 0;
  max-width: 100%;
}