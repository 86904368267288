/* Modal Mimic Page */
.modalMimicPage {
   background-color: #F7F7F7;
   padding-top: 25px;
}

.modalMimicPageModalBox {
   border: 1px solid #D9DBDD;
   background-color: #FFFFFF;
   padding: 30px; /*50px 30px 50px*/
   border-radius: 16px;
}

.modalMimicPageModalFooter {
   max-width: 640px;
   text-align: center;
   padding: 20px 50px 20px 50px;
}

.pageHolder {
   min-height: calc(100vh - 187px);
}

.Page {
   padding: 20px;
   min-height: calc(100vh - 195px);
}