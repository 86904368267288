/* BASE CARDS */
.card {
   background-color: #fff;
   border-radius: 14px;
   margin: 10px;
   box-shadow: 3px 6px 15px 2px #DFDFDF;
}
.flatLeftCard {
   border-radius: 0px 14px 14px 0px;
}
.flatLeftCardBorder {
   border-radius: 0px 14px 14px 0px;
   border: 1px solid #ccc,
}
.cardHeader {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 10px;
   border-bottom: 1px solid #F2F3F3;
}
.cardTop {
   text-align: center;
   border-right: 1px solid #F2F3F3;
   margin-right: 5px;
}

.cardTop.left {
   font-weight: 600;
}

.selectedCard {
   border-left: 4px solid #7A64EB;
}

.cardTitle {
   flex: 1;
   font-weight: 600;
   padding-top: 10px;
   padding-bottom: 10px;
   border-bottom: 1px dotted #cccccc;
}


/* PROJECT CARDS */
.projectCard {
   background-color: #fff;
   border: 2px solid #F2F3F3;
}

@media (max-width: 400px) {
   .projectCard {
      width: 250px;
   }
}

@media (min-width: 400px) and (max-width: 550px) {
   .projectCard {
      width: 350px;
   }
}

@media (min-width: 550px) and (max-width: 768px) {
   .projectCard {
      width: 500px;
   }
}

@media (min-width: 768px) {
   .projectCard {
      width: 400px;
      margin: 10px;
   }
}

.projectCardHeader {
   display: flex;
   padding: 15px;
   justify-content: space-between;
}

.projectCardHeaderText {
   line-height: 30px;
   font-size: 20px;
   font-weight: 500;
   text-align: left;
}

.projectCardBody {
   padding: 15px;
   line-height: 24px;
   font-size: 16px;
   padding-top: 0px;
}

.projectCardFooter {
   padding: 15px;
   line-height: 24px;
   font-size: 16px;
   border-top: 1px solid #F2F3F3;
}