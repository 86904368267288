/* ---------------------------------
/*  Standard Buttons 
/* --------------------------------- */
button {
   font-family: 'Inter';
   padding: 4px 10px;
   border-radius: 6px;
   margin-left: 7px;
   margin-right: 7px;
   line-height: 24px;
   font-size: 14px;
   font-weight: 400;
   border: none;
   cursor: pointer;
}

.buttonGray {
   border: 1px solid #cccccc;
   color: #0E0F11;
   background-color: #fff;
}

.buttonClone {
   background-color: #7A64EB;
   color: #ffffff;
   border: none;
}
.buttonClone:hover {
   background-color: #644AE8;
}
.buttonClone:active {
   background-color: #5438E5;
}
.buttonClone:disabled {
   background-color: #cccccc;
   color: #ffffff;
   cursor: auto;
}

.buttonCloneLight {
   border: #7A64EB 1px solid;
   background-color: #ffffff;
   color: #7A64EB;
}

.buttonCloneLightPurple {
   background-color: #F3F1FE;
   color: #7A64EB;
}

.buttonCloneDark {
   border: #1E1147 1px solid;
   background-color: #1E1147;
   color: #ffffff;
}

.warningButton {
   background-color: #E35640;
   color: #ffffff;
   border: #E35640 1px solid;
}

.roundButton {
   background-color: #7A64EB;
   border-radius: 20px;
}
.roundButton:hover {
   background-color: #ffffff;
   color: #7A64EB;
   border-radius: 20px;
}

/* ---------------------------------
/*  Radio Buttons 
/* --------------------------------- */

input[type="radio"] {
   display: none;
 }
 
 input[type="radio"] + label {
   position: relative;
   padding-left: 15px; 
   cursor: pointer;
 }
 input[type="radio"]:disabled + label {
   cursor: default;
 }

 input[type="radio"] + label:before {
   content: '';
   position: absolute;
   left: 0;
   top: 3;
   margin-top: 5px;
   width: 8px; 
   height: 8px; 
   border: 2px solid #7A64EB; 
   border-radius: 50%; 
 }
 
 input[type="radio"]:disabled + label:before {
   border: 2px solid #cccccc; 
   cursor: default;
 }

 /* Style the dot inside the custom radio button when selected */
 input[type="radio"]:checked + label:before {
   background-color: #7A64EB;
 }
 input[type="radio"]:checked:disabled + label:before {
   background-color: #cccccc;
   cursor: default;
 }

/* ---------------------------------
/*  Unique Buttons 
/* --------------------------------- */
.viewEditButton {
   display: inline-block;
   padding: 8px 15px;
   background-color: #1a1161;
   color: #ffffff;
   text-decoration: none;
   border-radius: 6px;
   transition: background-color 0.3s ease-in-out;
   font-weight: 400;
}

.viewEditButton:hover {
   background-color: #a899a8;
   cursor: pointer;
}

/* ---------------------------------
/*  General
/* --------------------------------- */
textarea {
   padding: 5px;
   border: #ffffff 1px solid;
   width: 100%;
}

Input {
   font-family: 'Lato';
   border: #cccccc 1px solid;
   border-radius: 8px;
   font-size: 15px;
   padding: 5px;
   padding-left:10px;
   width: calc(100% - 12px);
   margin: 10px 0px;
}


Input::placeholder {
   color: #60646C; 
   opacity: 1; 
 }

.InputError {
   border: 1px solid #E35640;
   border-radius: 8px;
}

.basicSelect {
   padding: 5px;
   font-weight: 300;
   margin: 0px 10px;
}

/*
input {
   padding: 5px;
   width: calc(100% - 12px);;
   font-weight: 300;
   margin: 10px 0px;
}
*/

Input[type="checkbox"]:checked {
 background-color: #12AC7B;
}
/* ---------------------------------
/*  Multi-Select Dropdown
/* --------------------------------- */

.focusLabel {
   display:none;
   border: 1px solid #cccccc;
   margin-top: 5px;
}
.staticLabel {
   border: 1px solid #cccccc;
   margin-top: 5px;
}
.focusLabelText,
.staticLabelText {
   font-size: 14px;
   font-weight: 600;
   color: #1a1161;
   margin: 0px;
   padding: 0px;
   padding-left: 10px;
   padding-top: 5px;
   padding-bottom: 5px;
}
.dropdownCaret {
   padding-left: 10px;
   padding-right: 10px;
   color: #0E0F11;
}

.multiSelectDropdown__control,
.singleSelectDropdown__control,
.manualSelect,
.inputWithLabel,
textarea {
   font-family: 'Lato';
   font-size: 15px;
   border-radius: 8px;
   -webkit-align-items: left;
   -webkit-box-align: left;
   -ms-flex-align: left;
   align-items: left;
   text-align: left;
   padding-left: 5px;
   cursor: default;
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-flex-wrap: wrap;
   -webkit-flex-wrap: wrap;
   -ms-flex-wrap: wrap;
   flex-wrap: wrap;
   -webkit-box-pack: justify;
   -webkit-justify-content: space-between;
   justify-content: space-between;
   outline: 0 !important;
   position: relative;
   -webkit-transition: all 100ms;
   transition: all 100ms;
   background-color: #fff;
   border: 1px solid #ccc;
   box-sizing: border-box;
   resize: vertical;
   padding-left:10px;
}
.withFormMargin,
.singleSelectDropdown__control,
.manualSelect,
.inputWithLabel,
textarea {
   margin-top: 5px;
   margin-bottom: 0px;
}
.removeFormMargin {
   margin: 0px;
}
.ImageSizeDropdown .multiSelectDropdown__control {
   padding-left: 0px;
   min-height: 30px;
}
.multiSelectDropdown__control::placeholder,
.singleSelectDropdown__control::placeholder,
.manualSelect::placeholder,
.inputWithLabel::placeholder,
textarea::placeholder {
   color: #60646C; 
   opacity: .9; 
 }
.multiSelectDropdown__value-container, .manualSelect {
   padding: 2px;
}
.multiSelectDropdown__indicator {
   padding: 2px;
}
.multiSelectDropdown__menu, select option {
   background-color: #fff !important;
   color: #1a1161 !important;
   border-bottom-left-radius: 8px !important;
   border-bottom-right-radius: 8px !important;
   border: 1px solid #ccc !important;
   border-top: 1px solid #7A64EB !important;
   text-align: left;
   padding-left: 10px;
}
.multiSelectDropdown__menu-list {
   padding: 4px;
   color: #1a1161 !important;
   max-height:130px !important;
}
.multiSelectDropdown__option, option {
   padding: 4px;
   color: #1a1161;
}

.multiSelectDropdown__option:hover {
   border-left: 2px solid #7A64EB;
   background-color: #F6F5FF;
}
.multiSelectDropdown__clear-indicator {
   display: none !important;
}
.multiSelectDropdown__multi-value__label,
.multiSelectDropdown__input,
.singleSelectDropdown__input,
.multiSelectDropdown__multi-value {
   padding: 2px;
   border: 1px solid #fff;
   color: #1a1161;
   margin: 0px !important;
   background-color: #fff;
}
.multiSelectDropdown__multi-value__remove {
   color: #7A64EB;
   background-color: #fff;
}

.multiSelectDropdown__single-value, .inputWithLabel, .manualSelect {
   padding-left:5px;
}

.focusLabelField:focus-within:not(.readOnly),
.staticLabelField:focus-within,
.inputWithLabel:focus-within {
   border-bottom: 1px solid #7A64EB;
}
.focusLabelField:focus-within:not(.readOnly),
.staticLabelField {
   display:flex;
   align-items: stretch;
   border-bottom-left-radius: 8px;
   border-bottom-right-radius: 8px;
}
.focusLabelField:focus-within:not(.readOnly) .multiSelectDropdown,
.focusLabelField:focus-within:not(.readOnly) .inputWithLabel,
.staticLabelField .multiSelectDropdown,
.staticLabelField .inputWithLabel {
   flex-grow: 1;
   margin-left: -2px !important;
}
.focusLabelField:focus-within:not(.readOnly) .multiSelectDropdown__control,
.focusLabelField:focus-within:not(.readOnly) .inputWithLabel,
.staticLabelField .multiSelectDropdown__control,
.staticLabelField .inputWithLabel {
   /*width: 80%;
   display: inline-block;
   margin-left:0px;*/
   border-radius: 0px 8px 8px 0px;
   flex-grow:1;
   outline: 0!important;
   margin-bottom: 0px;
}
.focusLabelField:focus-within:not(.readOnly) .focusLabel,
.staticLabelField .staticLabel {
   display: flex;
   align-items: center;
   border-radius: 8px 0px 0px 8px;
   margin-right: 0px;
   background-color: #F2F3F3;
   padding-right: 10px;
}

/*********************************
 NEW CUSTOM FORM COMPONENTS
*********************************/
 .createableMulti {
   display: block;
 }
 .createableFocusLabel {
  display: none; 
  margin-top: 5px;
  align-content: center;
}
 .createableFocusLabelText {
   font-size: 14px;
   font-weight: 600;
   color: #1a1161;
   margin: 0px;
   padding: 0px;
   padding-left: 10px;
   padding-top: 5px;
   padding-bottom: 5px;
 }
 .dropdownIcon {
   padding-left: 10px;
   padding-right: 8px;
   float: right;
   color: #0e0f11;
   text-align: right;
   font-size: 14px;
 }
 .createableMulti__control,
 .singleSelectDropdown__control,
 .inputWithLabel,
 textarea {
   font-family: 'Lato';
   font-size: 15px;
   border-radius: 8px;
   -webkit-align-items: left;
   -webkit-box-align: left;
   -ms-flex-align: left;
   align-items: left;
   text-align: left;
   padding-left: 5px;
   cursor: default;
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-flex-wrap: wrap;
   -webkit-flex-wrap: wrap;
   -ms-flex-wrap: wrap;
   flex-wrap: wrap;
   -webkit-box-pack: justify;
   -webkit-justify-content: space-between;
   justify-content: space-between;
   outline: 0 !important;
   position: relative;
   -webkit-transition: all 100ms;
   transition: all 100ms;
   background-color: #fff;
   box-sizing: border-box;
   resize: vertical;
   padding-left: 10px;
 }
 .withFormMargin,
 .singleSelectDropdown__control,
 .inputWithLabel,
 textarea {
   margin-top: 5px;
   margin-bottom: 0px;
 }
 .removeFormMargin {
   margin: 0px;
 }
 .ImageSizeDropdown .createableMulti__control {
   padding-left: 0px;
   min-height: 30px;
 }
 .createableMulti__control::placeholder,
 .singleSelectDropdown__control::placeholder,
 .inputWithLabel::placeholder,
 textarea::placeholder {
   color: #60646c;
   opacity: 0.9;
 }
 .createableMulti__value-container {
   padding: 2px;
 }
 .createableMulti__indicator {
   padding: 2px;
 }

 .selectedOption {
   display: none;
 }

 .createableMulti__menu,
 select option {
   background-color: #fff !important;
   color: #1a1161 !important;
   border-bottom-left-radius: 8px !important;
   border-bottom-right-radius: 8px !important;
   border: 1px solid #ccc !important;
   text-align: left;
   padding-left: 10px;
   width: 100%;
   overflow-y: auto;
   position: absolute;
   top: 100%;
   z-index: 1;
 }
 .createableMulti__menu-list {
   padding: 4px;
   color: #1a1161 !important;
   max-height: 130px !important;
   list-style: none;
   background-color: #fff;
 }
 .createableMulti__option,
 option {
   padding: 4px;
   color: #1a1161;
 }

 .createableMulti__option:hover {
   border-left: 2px solid #7a64eb;
   background-color: #f6f5ff;
 }
 .createableMulti__clear-indicator {
   display: none !important;
 }
 .createableMulti__multi-value__label,
 .createableMulti__input,
 .singleSelectDropdown__input,
 .createableMulti__multi-value {
   padding: 2px;
   color: #1a1161;
   margin: 0px !important;
   font-family: 'Lato';
   border: none;
   font-size: 15px;
   text-overflow: ellipsis;
 }

 .createableMulti__multi-value {
   max-width: 100%;
   min-width: 100px;
 }
 .createableMulti__multi-value__label {
   flex-grow: 1;
 }
 .createableMulti__multi-value__remove {
   color: #7a64eb;
   background-color: #fff;
   margin-left: 0px;
   cursor: pointer;
   font-size: 12px;
   flex-shrink: 0;
   align-self: center;
 }

 .createableMulti__single-value,
 .inputWithLabel {
   padding-left: 5px;
 }
 .createableMulti__multi-value__label {
   display: inline-block;
 }

 .createableFocusLabelFieldVisible {
  display: flex;
  align-items: stretch;
}


 .createableFocusLabelFieldVisible .createableMulti,
 .createableFocusLabelFieldVisible {
   flex-grow: 1;
   margin-left: -2px !important;
 }
 
 .createableFocusLabelFieldVisible .createableMulti__control,
 .createableFocusLabelFieldVisible {
   border-radius: 0px 8px 8px 0px;
   flex-grow: 1;
   outline: 0 !important;
   margin-bottom: 0px;
 }
 .createableFocusLabelFieldVisible .createableFocusLabel {
   display: flex;
  align-items: center;
  border-radius: 8px 0px 0px 8px;
  margin-right: 0px;
  background-color: #f2f3f3;
  padding-right: 10px;
  flex: 1;
  border: 1px solid #ccc;
 }

 .createableFocusLabelFieldVisible .createableFocusLabel {
   border-bottom: 1px solid #7a64eb;
   border-bottom-left-radius: 8px;
 }

 .createableFocusLabelFieldVisible .createableMulti {
   flex: 3;
 }

 .createableFocusLabelFieldVisible .createableMulti__control {
   border-bottom: 1px solid #7a64eb;
   border-bottom-right-radius: 8px;
   align-self: stretch;
 }

 .createableMultiContainer {
   position: relative;
   margin-bottom: 20px;
   font-family: Arial, sans-serif;
 }
 .multiSelectLabel {
   font-weight: bold;
   display: block;
   margin-bottom: 8px;
 }
 .createableMulti__control {
   display: flex;
   align-items: center;
   padding: 0px 5px;
   border: 1px solid #ccc;
   border-radius: 8px;
   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
   position: relative;
   width: 100%;
 }
 .createableMulti__value-container {
   display: flex;
   align-items: center;
   gap: 5px;
   flex-grow: 1;
   overflow: hidden;
 }

 .createableMulti__multi-value {
   display: flex;
   align-items: center;
   padding: 0px !important;
   border-radius: 4px;
   font-size: 14px;
   flex-shrink: 0;
   margin-right: 8px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
 }

 .createableMulti__multi-value__label {
   flex-grow: 1; /* Ensure the label takes the remaining space */
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
 }
 .createableMulti__input-container {
   border: none;
   outline: none;
   padding: 5px;
   min-width: 60px;
   flex: 1;
   min-height: 18px;
 }
 .checkMark {
   margin-left: 10px;
 }
 .readOnly {
    background-color: #f0f0f0 !important;
    cursor: not-allowed !important;
  }
 