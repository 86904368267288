.FullWidth {
   width: 100%;
   max-width: calc(100vw);
   display:block;
}

.OneHalfFlex {
   flex-basis: calc(50% - 50px);
   margin: 5px;
   max-width: calc(50% - 50px);
}


.FullWidthFlex {
   display: flex;
   flex-wrap: wrap;
}


.fullWidthContainer {
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   flex-wrap: wrap;
}

.fullWidthContainer.staff {
   flex-flow:column;
   align-items:unset;
}

.fullWidthContainer.top {
   align-items:baseline;
}

.OnePortionFlex {
   flex: 1 !important;
}
.TwoPortionFlex {
   flex: 2 !important;
}
.ThreePortionFlex {
   flex: 3 !important;
}
.FourPortionFlex {
   flex: 4 !important;
}
.FivePortionFlex {
   flex: 5 !important;
}
.SevenPortionFlex {
   flex: 7 !important;
}
.EightPortionFlex {
   flex: 8 !important;
}
.NinePortionFlex {
   flex: 9 !important;
}
.TenPortionFlex {
   flex: 10 !important;
}
.FlexWrap {
   flex-wrap: wrap;
   min-width:1px;
}

.mobileVerticalFlex {
   flex-direction: row;
}

@media (max-width: 599px) {
.mobileVerticalFlex  {
   flex-direction: column;
}
}